import { isBefore, isAfter } from '@formkit/tempo';
import type { DateIsBetweenArgs } from '~/types';

export const dateIsBetween = (compareDate: Date | string, args: DateIsBetweenArgs): boolean => {
    const cd = new Date(compareDate);
    const min = new Date(args.minDate);
    const max = new Date(args.maxDate);

    return isBefore(cd, max) && isAfter(cd, min);
};
